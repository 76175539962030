<script>
import Layout from '@layouts/main'

import { setComputed } from '@state/helpers'

import { getScrollTop, scrollTo } from '@utils/scroll'

export default {
  components: { Layout},
  data() {
    return {
      scrollTop: 0,
      scrollEnd: null,
      slogan: {
        'zh-TW': {
          title: "一個人也要好好吃頓飯",
          content: "來點一人份的浪漫"
        },
        'en': {
          title: "Eat well even if you're by yourself",
          content: "Enjoy being the one and only"
        }
      }
    }
  },
  computed: {
    ...setComputed,
    sloganModule() {
      return this.currentLanguage === 'zh-TW'?  this.slogan['zh-TW']:this.slogan['en']
    },
    getBookingIframeUrl() {
      const url = "https://inline.app/booking/-KvAWXGwv_eS0sJldRVK/-KvAWXHxx0JjmK_At-4s"
      return this.currentLanguage === 'zh-TW'? url:url+"/?language=en"
    }
  },
  destroyed() {
    document.removeEventListener("scroll", this.onScroll, { passive: false });
  },
  mounted() {
    document.addEventListener("scroll", this.onScroll, { passive: false });
  },
  methods: {
    onScroll() {
      this.scrollTop = this.getScrollTop();

      if (this.scrollEnd) {
        clearTimeout(this.scrollEnd);
      }

      this.scrollEnd = setTimeout(() => {
        this.supportScroll();
      }, 200);
    },
    supportScroll() {
      const scrollTop = this.getScrollTop();
      const blockOffsetTop = document.querySelector(`.${this.$style.sectionBooking}`).offsetTop;

      if(Math.abs(scrollTop - (blockOffsetTop)) < 100) {
        this.scrollTo(this.$style.sectionBooking, 0, 'linear')
      }
    },
    getScrollTop,
    scrollTo
  }
}
</script>

<template>
  <Layout>
    <section :class="$style.sectionBanner" class="section" data-section-logo-color="#000">
      <div :class="$style.contentBox" :style="{ backgroundPositionY: `${-(scrollTop * 0.6)}px` }">
        <div class="container" :class="$style.container">
          <h2 :class="$style.title" v-html="sloganModule.title"></h2>
          <p :class="$style.content" v-html="sloganModule.content"></p>
        </div>
      </div>
    </section>
    <section :class="$style.sectionBooking" class="section" data-section-logo-color="#000">
      <div class="container">
        <div :class="$style.bookingIframeBox">
          <iframe
            :class="$style.bookingIframe"
            :src="getBookingIframeUrl"></iframe>
          <div :class="$style.loading">
            <img src="@assets/images/booking/inline_logo.png">
            <font-awesome-icon
              :class="$style.icon"
              class="fa-spin"
              :icon="['fal', 'circle-notch']"></font-awesome-icon>
          </div>
        </div>

      </div>
    </section>
  </Layout>
</template>
<style lang="scss" module>
  $banner-height: 782px;
  .sectionBanner {
    padding: 350px 0 176px;
    @include touch {
      padding: 150px 0 124px;
    }
    .container {
      padding: 125px 15px 95px;

      @include touch {
        padding: 80px 20px;
      }
    }
    .contentBox {
      background: #fafafa;
      background-image: url(@assets/images/booking/logo.svg);
      background-size: 440px;
      background-repeat: no-repeat;
      background-position-x: 20%;
      height: 350px;
      text-align: right;
      @include touch {
        background-image: url(@assets/images/booking/logo_mobile.svg);
        background-size: auto;
        background-position-x: 20px;
        background-position-y: center !important;
        height: 276px;
      }
      .container {
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: end;
      }
      .title {
        max-width: 70%;
        font-size: 40px;
        line-height: 30px;
        letter-spacing: 4.8px;
        font-weight: 500;
        margin-bottom: 28px;
        line-height: 1.5;
        @include touch {
          font-size: 24px;
          letter-spacing: 2.88px;
          margin-bottom: 14px;
        }
      }
      .content {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 1.12px;
        font-weight: 500;
        margin-bottom: 0;
        @include touch {
          font-size: 12px;
          letter-spacing: 0.6px;
        }
      }
    }
  }
  .sectionBooking {
    padding: 0 0 24px;
    position: relative;
    z-index: 1;
    position: relative;
    pointer-events: none;
    background-color: transparent;
    background-repeat: no-repeat;

    .bookingIframeBox {
      background: #fff;
      position: relative;
      z-index: 0;
      .bookingIframe {
        width: 100%;
        height: 100vh;
        border: 0px solid transparent;
        pointer-events: auto;
      }
      .loading {
        width: 150px;
        height: 150px;
        display: flex;
        align-items: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        .icon {
          color: #ff5722;
          font-size: 150px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -75px;
          margin-top: -75px;
          stroke: #fff;
          opacity: 0.75;
          stroke-width: 25px;
        }
        img {
          max-width: 80%;
          margin: auto;
        }
      }
    }
  }
</style>
